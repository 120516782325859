.cursor {
  align-items: center;
  background-color: #F9D4CE;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0;
  border-radius: 50%;
  color: transparent;
  display: flex;
  font-weight: 600;
  height: 12px;
  justify-content: center;
  left: -100px;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-align: center;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
  transition-property: background, color, width, height;
  width: 12px;
  word-spacing: 100px;
  z-index: 99999; }

html {
  --top-spacing: 105px; }

body {
  margin: var(--top-spacing) auto;
  scroll-padding-top: var(--top-spacing); }

.pull-right {
  float: right !important; }

.form-control {
  cursor: none; }

.gs-loader {
  -ms-transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  -webkit-transform: translateZ(0);
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  border-radius: 50%;
  color: #4B286D;
  height: 1em;
  font-size: 60px;
  margin: 72px auto;
  overflow: hidden;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 1em; }

.gs-loader-small {
  -ms-transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  -webkit-transform: translateZ(0);
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  border-radius: 50%;
  color: #4B286D;
  font-size: 36px;
  height: 1em;
  margin: 30px auto;
  overflow: hidden;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 1em; }

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.gs-navbar {
  align-items: center;
  background: #FBFBFD;
  border-bottom: 1px solid #F6F6F6;
  border-left: none;
  border-right: none;
  border-top: none;
  box-shadow: 0 0 2px #CCCCCC;
  display: flex;
  height: 105px;
  justify-content: space-between;
  overflow: auto;
  width: 100%; }

.resume {
  background-color: #EEEEEE;
  padding: 30px; }

.resume-download {
  font-size: 18px;
  font-weight: 700;
  margin-top: 25px; }

.resume-pdf {
  height: 100%;
  width: 100%; }

.contact-me {
  background-image: url(../../assets/images/ivory-tile.jpeg);
  min-height: 700px; }

.gs-card-title {
  background-color: #000000;
  color: #FFFFFF;
  height: 60px; }

.gs-card-title-text {
  margin-top: 16px;
  text-align: center; }

.email-button {
  margin-top: 20px;
  width: 100%; }

footer.hello-footer {
  background: #FBFBFD;
  border: 1px solid #F6F6F6;
  bottom: 0;
  padding: 25px 0;
  text-align: center;
  position: fixed;
  width: 100%; }

footer.hello-footer span.footer-copyright {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  text-transform: none; }

ul.footer-icon {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: center; }

ul.footer-icon li {
  float: right; }

ul.footer-icon li a {
  background-color: #16222A;
  border-radius: 100%;
  color: #E4E5E6;
  font-size: 20px;
  display: block;
  height: 40px;
  line-height: 40px;
  margin-right: 20px;
  outline: none;
  width: 40px; }

ul.footer-icon li a:hover,
ul.footer-icon li a:focus,
ul.footer-icon li a:active {
  background-color: #B00E25;
  font-size: 24px;
  transition: all 0.2s; }

.welcome-body {
  background: #F3F4F6;
  margin-top: 25px; }

.seung-hwan {
  font-size: 50px;
  font-weight: 900;
  text-align: right; }

.g-ul {
  font-size: 120px;
  font-weight: 900;
  margin-top: -36px;
  text-align: right; }

.um {
  font-size: 180px;
  font-weight: 900;
  margin-top: -78px;
  text-align: right; }

.my-picture {
  width: 100%; }

.link-g-ul {
  margin: 0;
  text-align: right; }

.hello-g-ul {
  color: #EBCCD1; }

.hello-g-ul:hover,
.hello-g-ul:focus,
.hello-g-ul:active {
  color: #B00E25;
  font-size: 38px;
  transition: all 0.2s; }

.metronome-g-ul {
  color: #BEBEBE; }

.metronome-g-ul:hover,
.metronome-g-ul:focus,
.metronome-g-ul:active {
  color: #16222A;
  font-size: 38px;
  transition: all 0.2s; }

.github-g-ul {
  color: #4972A1; }

.github-g-ul:hover,
.github-g-ul:focus,
.github-g-ul:active {
  color: #000080;
  font-size: 38px;
  transition: all 0.2s; }

.linkedin-g-ul {
  color: #F9D4CE; }

.linkedin-g-ul:hover,
.linkedin-g-ul:focus,
.linkedin-g-ul:active {
  color: #FF0084;
  font-size: 38px;
  transition: all 0.2s; }

.my-description {
  color: #1F1C18; }

.course-info-body {
  background-color: #F3F4F6; }

.g-table {
  background-image: linear-gradient(to bottom right, #e8eaee, #F3F4F6);
  border-collapse: collapse;
  border-radius: 6px;
  border-spacing: 0;
  box-shadow: 1px 1px 5px 3px grey;
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px; }

.g-table-header {
  background-color: #000000;
  color: #FFFFFF; }

#monitor {
  background: #000000;
  border-bottom-left-radius: 50% 2%;
  border-bottom-right-radius: 50% 2%;
  border-radius: 10px;
  border-top: 3px solid #A9A9A9;
  margin: 0 5% 5% 5%;
  padding: 2% 2% 4% 2%;
  position: relative;
  transition: margin-right 1s; }

#monitor:after {
  background: #DDDDDD;
  border-radius: 50%;
  bottom: 3%;
  box-shadow: 0 0 3px 0 #FFFFFF;
  content: '';
  display: block;
  height: .5%;
  left: 36%;
  position: absolute;
  width: 28%; }

#monitorscreen {
  position: relative;
  background-color: #777;
  background-size: cover;
  background-position: top center;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden; }

@media all and (min-width: 960px) {
  #monitor {
    -o-animation: tvflicker .5s infinite alternate;
    -moz-animation: tvflicker .5s infinite alternate;
    -webkit-animation: tvflicker .2s infinite alternate;
    animation: tvflicker .5s infinite alternate; }
  @-webkit-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(200, 235, 255, 0.4); }
    100% {
      box-shadow: 0 0 95px 0 rgba(200, 230, 255, 0.45); } }
  @-moz-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4); }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6); } }
  @-o-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4); }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6); } }
  @keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4); }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6); } } }

.video {
  width: 100%; }

.beach-button {
  background: #A14F49;
  border: none;
  border-radius: 50%;
  height: 140px;
  margin-bottom: 20px;
  width: 140px; }

.play-button {
  background: #16222A;
  border: none;
  border-radius: 50%;
  height: 140px;
  margin-bottom: 20px;
  width: 140px; }

.rain-button {
  background: #4972A1;
  border: none;
  border-radius: 50%;
  height: 140px;
  margin-bottom: 20px;
  width: 140px; }

.beach-button:active,
.beach-button:focus,
.beach-button:hover {
  background: #134E5E;
  border: #134E5E; }

.play-button:active,
.play-button:focus,
.play-button:hover {
  background: #134E5E;
  border: #134E5E; }

.rain-button:active,
.rain-button:focus,
.rain-button:hover {
  background: #134E5E;
  border: #134E5E; }

.page-not-found {
  margin-top: 25px;
  text-align: center; }

.not-found-span {
  background-clip: text;
  /* For Chrome and Safari */
  background-image: -webkit-linear-gradient(left, #8A2387, #FF0FF0);
  /* For old Fx (3.6 to 15) */
  background-image: -moz-linear-gradient(left, #8A2387, #FF0FF0);
  /* For pre-releases of IE 10*/
  background-image: -ms-linear-gradient(left, #8A2387, #FF0FF0);
  /* For old Opera (11.1 to 12.0) */
  background-image: -o-linear-gradient(left, #8A2387, #FF0FF0);
  /* Standard syntax; must be last */
  background-image: linear-gradient(to right, #8A2387, #FF0FF0);
  color: transparent;
  font-size: 38px;
  font-weight: 800;
  -webkit-background-clip: text; }
